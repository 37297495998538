<template>
    <!--投票公示页面 -->
    <div>
        <div class="table_content">
            <commonTitle></commonTitle>
            <template>
                <el-form label-width="80px" :inline="true">
                    <el-form-item>
                        <div>
                            <div style="margin-top: 15px; float: left;">
                                投票信息
                            </div>
                            <div style="margin-top: 15px; float: left; position: absolute; left: 450px;">
                                倒计时：
                            </div>
                            <div style="margin-left: 524px;">
                                <flip-countdown :labels="{
                              days: '天',
                              hours: '时',
                              minutes: '分',
                              seconds: '秒',
                            }" :deadline="this.endTime"></flip-countdown>
                            </div>
                        </div>
                    </el-form-item>
                </el-form>
                <el-card>
                    <el-table
                            :data="tableData"
                            
                            v-loading="loading"
                            element-loading-text="拼命加载中"
                            style="width: 100%">
                        <el-table-column
                                prop="villageName"
                                label="小区名称"
                                show-overflow-tooltip
                        >
                        </el-table-column>
                        <el-table-column
                                prop="towerNum"
                                label="楼幢号"
                                show-overflow-tooltip
                        >
                        </el-table-column>
                        <el-table-column
                                prop="unitName"
                                label="单元"
                                show-overflow-tooltip
                        >
                        </el-table-column>
                        <el-table-column
                                prop="houseNum"
                                label="户室编号"
                                show-overflow-tooltip
                        >
                        </el-table-column>
                        <el-table-column
                                prop="ownerName"
                                label="业主姓名"
                                show-overflow-tooltip
                        >
                        </el-table-column>

                        <el-table-column
                                prop="money"
                                label="分摊金额"
                                show-overflow-tooltip
                        >
                        </el-table-column>
                        <el-table-column
                                prop="voteResult"
                                label="投票结果"
                                :formatter="formatVoteResult"
                                show-overflow-tooltip
                        >
                        </el-table-column>

                        <el-table-column
                                prop="updateTime"
                                label="投票时间"
                                show-overflow-tooltip
                        >
                        </el-table-column>

<!--                        <el-table-column-->
<!--                                fixed="right"-->
<!--                                label="操作"-->
<!--                                width="120">-->
<!--                            <template slot-scope="scope">-->
<!--                                <el-button type="text" size="small" @click="detail(scope.row)">详情</el-button>-->
<!--                            </template>-->
<!--                        </el-table-column>-->
                    </el-table>

                    <br>
                    <span style="color: black;font-weight: bold;" v-show="show">合计：</span>
                    <div style="margin-top: -23px;margin-left: 40px;">

                        <span class="statis" v-show="show">同意：{{agree}}人 占比{{agreePrecent}}%</span>
                        <span class="statis" v-show="show">不同意：{{noAgree}}人 占比{{noAgreePrecent}}%</span>
                        <span class="statis" v-show="show">未投票：{{noVote}}人 占比{{noVotePrecent}}%</span>
                    </div>

                    <br>
                    <div style="margin-top: -7px;margin-left: 40px;">
                        <span class="statis" v-show="show">同意面积占比:{{areaPrecent}}%</span>
                        <span class="statis" v-show="show">投票业主数是否超过2/3:{{isAlreadyVote}}</span>
                        <span class="statis" v-show="show">面积是否超过2/3:{{isAlreadyArea}}</span>
                    </div>

                </el-card>
            </template>
            <el-row style="margin-top: 10px; display: flex; justify-content: center;">
                <el-button type="primary" size="small" @click.prevent="submitMoney"  icon="el-icon-finished">提交拨款申请</el-button>
                <el-button type="primary" v-show="line1State" size="small" @click.prevent="download" >投票结果下载</el-button>
                <el-button type="primary" @click.prevent="upload" size="small"  v-show="lineState">投票结果Excel上传<i
                        class="el-icon-upload el-icon--right"></i>
                </el-button>

                <el-button type="primary" @click.prevent="upLoadImage" size="small"  v-show="lineState">投票结果扫描件上传<i
                        class="el-icon-upload el-icon--right"></i>
                </el-button>
                <el-button @click="back" type="primary"  size="small"  class="btn" >返回首页</el-button>
            </el-row>
            <el-pagination
                    background
                    style="margin-top: 30px; display: flex; justify-content: center;"
                    layout="prev, pager, next"
                    :page-size="15"
                    @current-change="handleCurrentChange"
                    :total="totalNum">
            </el-pagination>
        </div>

        <!--上传弹框-->
        <el-dialog
                title="上传"
                :visible.sync="dialogVisible"
                width="30%"
                style="text-align: center"
        >
            <el-upload
                    accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    class="upload-demo"
                    drag
                    ref="upload"
                    :file-list="fileList"
                    :action="upUrl"
                    :limit="1"
                    :auto-upload="false"
                    :before-upload="beforeAvatarUpload"
                    :on-success="handleSuccess"
                    multiple>
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                <div class="el-upload__tip" slot="tip">只能上传xls/xlsx 格式文件
                    <el-link :underline="false" type="primary" @click="downTemplate">模板下载</el-link>
                </div>
            </el-upload>
            <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="submitUpload">上 传</el-button>
  </span>
        </el-dialog>

        <!--图片上传弹框-->
        <el-dialog
                title="投票结果上传"
                :visible.sync="dialogVisibleImage"
                width="30%"
                style="text-align: center"
        >
            <el-upload
                    class="upload-demo"
                    accept="image/png,image/jpg"
                    drag
                    ref="imageUpload"
                    :file-list="imageList"
                    :action="imageUpUrl"
                    :limit="1"
                    :auto-upload="false"
                    :before-upload="beforeAvatarUploadImage"
                    :on-success="handleSuccessImage"
                    multiple>
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                <div class="el-upload__tip" slot="tip">只能上传png/jpg文件</div>
            </el-upload>
            <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisibleImage = false">取 消</el-button>
    <el-button type="primary" @click="submitUploadImage">上 传</el-button>
  </span>
        </el-dialog>
    </div>
</template>

<script>
    import commonTitle from "../table/commonTitle";
    import FlipCountdown from '../common/FlipCountdown';


    export default {
        name: "votePublic",
        components: {
            commonTitle,
            FlipCountdown
        },
        data() {
            return {
                url: this.$Config.base_server,
                createRepairId:'',
                tableData: [],//表格数据
                endTime: '',
                totalNum: 15,
                name:'',//维修项目名称
                agreePrecent: '',//同意占比
                noAgreePrecent: '',//不同意占比
                noVotePrecent: '',//未投票占比
                agree: '',//同意的人
                noAgree: '',//不同意的人
                noVote: '',//未投票
                areaPrecent: '',//面积占比
                isAlreadyVote: '',//业主投票是否超过2/3
                isAlreadyArea: '',//同意投票的面积是否超过2/3
                show: false,
                loading: true,
                publicData: {},
                dialogVisible: false,
                searchValue: '',//搜索的值
                lineState: '',//是否显示上传按钮  线上不显示 线下显示
                line1State: true,
                upUrl: '',//上传模板的url
                fileList: [],
                dialogVisibleImage: false, //上传图片的弹框
                imageList: [],
                imageUpUrl: '',//图片上传地址
                contractMoney: '',
                repairId:''
            }
        },
        created() {
            this.publicData = this.$route.query.publicData
            this.createRepairId=this.$route.query.createRepairId
            this.lineState=this.$route.query.lineState
            this.endTime = this .$route.query.endTime
            this.name = this.$route.query.name
            this.repairId=this.$route.query.repairId
            this.common();
            //excel上传文件地址
            this.upUrl = this.url + "/api-public/importRepairDeatail?access_token=" + localStorage.getItem("token");
            //图片上传地址
            this.imageUpUrl = this.url + "/api-file/files/anon?access_token=" + localStorage.getItem("token");
        }, methods: {
            common(currentPage = 0) {
                //初始化数据
                //获取所有的维修资金项目列表
                const _this = this;
                const data = {
                    page: currentPage,
                    limit: 15,
                    repairId: this.createRepairId
                }
                $.ajax({
                    url: _this.url + '/api-public/repairShareDetail/findRepairDetailByRepairById?access_token=' + localStorage.getItem("token"),
                    type: 'get',
                    data: data,
                    contentType: 'application/json',
                    success: function (res) {
                        _this.loading = false;
                        _this.totalNum = res.count;
                        _this.tableData = res.data;
                        if (res.data[0].agree != null || res.data[0].agree != '') {
                            _this.show = true;
                            _this.agree = res.data[0].agree;
                            _this.agreePrecent = (res.data[0].agree / res.count).toFixed(2) * 100;
                        }
                        if (res.data[0].noAgree != null || res.data[0].noAgree != '') {
                            _this.show = true;
                            _this.noAgree = res.data[0].noAgree;
                            _this.noAgreePrecent = (res.data[0].noAgree / res.count).toFixed(2) * 100;
                        }
                        if (res.data[0].noVote != null || res.data[0].noVote != '') {
                            _this.show = true;
                            _this.noVote = res.data[0].noVote;
                            _this.noVotePrecent = (res.data[0].noVote / res.count).toFixed(2) * 100;
                        }
                        if (res.data[0].areaPrecent != null || res.data[0].areaPrecent != '') {
                            _this.areaPrecent = (res.data[0].areaPrecent/1).toFixed(2) * 100;
                            //判断业主同意投票面积是否超过2/3
                            if (res.data[0].areaPrecent > (2 / 3)) {
                                _this.isAlreadyArea = "超过";
                            } else {
                                _this.isAlreadyArea = "不超过";
                            }
                        }
                        //判断业主投票是否超过2/3
                        if ((res.data[0].agree != null || res.data[0].agree != '') && (res.data[0].noAgree != null || res.data[0].noAgree != '')) {
                            var isAreadyVote = (res.data[0].agree - 0) + (res.data[0].noAgree - 0);
                            if ((isAreadyVote / res.count) > (2 / 3)) {
                                _this.isAlreadyVote = "超过";
                            } else {
                                _this.isAlreadyVote = "不超过";
                            }
                        }
                        //是否显示上传按钮
                        if (res.data[0].publicity == 1) {
                            //0 线上投票 1线下投票
                            _this.lineState = false;
                            _this.line1State = true;
                        }
                        if (res.data[0].publicity == 2) {
                            //0 线上投票 1线下投票
                            _this.lineState = true;
                            _this.line1State = false;
                        }

                    }
                });
            }, handleCurrentChange(val) {//当前页数改变
                this.loading = true;
                this.common(val)
            }, formatVoteResult(row) {
                //格式化投票结果
                if (row.voteResult == 0) {
                    return "已投票";
                } else if (row.voteResult == 1) {
                    return "已投票";
                } else if (row.voteResult == 2) {
                    return "未投票";
                }
            }, back() {
                //返回按钮
                this.$router.push({ name: "repairItem" });
            }, detail(row) {
                this.$router.push({path: 'sharePlan', query: {shareId: row.shareId, houseMoney: row.money}});
            }, upload() {
                this.dialogVisible = true;
            }, submitMoney(){
                const that = this;
                    if (that.isAlreadyArea=='超过'||that.isAlreadyVote=='超过'){
                        $.ajax({
                            url: that.url + '/api-public/createRepair/list?access_token=' + localStorage.getItem("token"),
                            type: 'get',
                            data: {
                              createRepairId:that.createRepairId
                            },
                            contentType: 'application/json',
                            success: function (res){
                                that.contractMoney = res.data[0].contractMoney;
                                const  shareId=res.data[0].shareId;
                                that.$router.push({path: 'WuYeManagement', query: {shareId:shareId,publicData: that.publicData,active: 4,fundingPlan: 1,txtNumber: 2,name: that.name,createRepairId: that.createRepairId,contractMoney: that.contractMoney}});
                            }
                        })
                    }else if (Date.parse(that.endTime)>new Date()) {
                        this.$message({message:'投票还未结束，请等待投票结束后继续操作！',type: 'warning'});
                    }else {
                        this.$message({message:"很遗憾，该维修项目投票未通过，请从新发起投票或改为应急维修项目",type:'warning'});
                    }

            }, search(currentPage = 0) {
                const _this = this;
                _this.loading = true;
                const data = {
                    page: currentPage,
                    limit: 15,
                    repairId: _this.createRepairId,
                    ownerName: _this.searchValue
                }
                $.ajax({
                    url: _this.url + '/api-public/repairShareDetail/findRepairDetailByRepairById?access_token=' + localStorage.getItem("token"),
                    type: 'get',
                    data: data,
                    contentType: 'application/json',
                    success: function (res) {
                        //是否显示上传按钮
                        if (res.data[0].lineState == 0) {
                            //0 线上投票 1线下投票
                            _this.lineState = false;
                        }
                        if (res.data[0].lineState == 1) {
                            //0 线上投票 1线下投票
                            _this.lineState = true;
                        }
                        _this.loading = false;
                        _this.totalNum = res.count;
                        _this.tableData = res.data;
                        _this.loading = false;
                    }
                });

            }, downTemplate() {
                window.location.href = this.url + "/api-public/exportRepairDeatail?repairId=" + this.createRepairId + "&access_token=" + localStorage.getItem("token");
            }, download() {
                window.location.href = this.url + "/api-public/exportRepairVote?repairId=" + this.createRepairId+ "&access_token=" + localStorage.getItem("token");
            }, importUser() {
                // this.importUserDialog = true;
            }, submitUpload() {
                //确定上传按钮
                this.$refs.upload.submit();
            }, submitUploadImage() {
                //确定上传按钮
                this.$refs.imageUpload.submit();
            }, beforeAvatarUpload(file) {
                //上传前进行校验格式
                console.log('上传前');
                const Xls = file.name.split('.');
                if (Xls[1] === 'xls' || Xls[1] === 'xlsx') {
                    return file;
                } else {
                    this.$message.error('上传文件只能是 xls/xlsx 格式')
                    return false
                }
            }, beforeAvatarUploadImage(file) {
                //图片上传前进行校验格式
                console.log('上传前');
                const Xls = file.name.split('.');
                if (Xls[1] === 'jpg' || Xls[1] === 'png') {
                    return file;
                } else {
                    this.$message.error('上传文件只能是 jpg/png 格式')
                    return false
                }
            }, handleSuccess(response, file, fileList) {
                if (response.resp_code == 0) {
                    //上传成功回调
                    this.$message({
                        type: 'success',
                        message: response.resp_msg,
                        duration: 2000
                    });
                    //关闭上传弹框
                    this.dialogVisible = false;
                    this.loading = true;
                    this.common();
                    this.$refs['upload'].clearFiles();
                }
                if (response.resp_code == 1) {
                    //上传成功回调
                    this.$message({
                        type: 'error',
                        message: response.resp_msg,
                    });
                    //关闭上传弹框
                    this.dialogVisible = false;
                    this.loading = true;
                    this.common();
                    this.$refs['upload'].clearFiles();
                }
            }, upLoadImage() {
                //上传图片
                this.dialogVisibleImage = true;
            }, handleSuccessImage(response, file, imageList) {
                //图片上传成功回调
                if (response.url != null || response.url != '' || response.url != undefined) {
                    //调用updateImageurl方法 跟新name url
                    const data = {
                        imagePath: response.id,
                        imageName: response.name,
                        id: this.repairId //维修项目的id
                    }
                    const _this = this;
                    $.ajax({
                        url: _this.url + '/api-public/updateImageurl?access_token=' + localStorage.getItem("token"),
                        type: 'get',
                        data: data,
                        success: function (result) {
                            console.log(result);
                        }
                    });

                    //上传成功回调
                    this.$message({
                        type: 'success',
                        message: "上传成功",
                        duration: 2000
                    });
                    //关闭上传弹框
                    this.dialogVisibleImage = false;
                    this.loading = false;
                    this.$refs['imageUpload'].clearFiles();
                } else {
                    this.$message({
                        type: 'error',
                        message: "上传失败",
                        duration: 2000
                    });
                    //关闭上传弹框
                    this.dialogVisibleImage = false;
                    this.loading = false;
                    this.$refs['imageUpload'].clearFiles();
                }
            }
        }
    }
</script>

<style scoped>
    .statis {
        margin-left: 35px;
        font-size: 15px;
        color: #8E9198;
    }

    /deep/ .flip-card__top,
    /deep/ .flip-card__bottom,
    /deep/ .flip-card__back-bottom,
    /deep/ .flip-card__back::before,
    /deep/ .flip-card__back::after,
    /deep/ .flip-card__top-4digits,
    /deep/ .flip-card__bottom-4digits,
    /deep/ .flip-card__back-bottom-4digits,
    /deep/ .flip-card__back-4digits::before,
    /deep/ .flip-card__back-4digits::after,
    /deep/ .flip-card__bottom,
    /deep/ .flip-card__back-bottom,
    /deep/ .flip-card__bottom-4digits,
    /deep/ .flip-card__back-bottom-4digits
    {
        color: #fff!important;
    }
</style>